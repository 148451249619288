<!--提现管理-->
<template>
    <div class="mainBox">
        <!--主要内容-->
        <div class="table" style="height:calc( 100% - 119px );margin-top: 20px;">
            <el-table :data="list" stripe :header-cell-style="{background:'#fafdff',color:'#606266',fontWeight:'normal'}" @sort-change='getList()' height="calc( 100% - 41px )">
                <el-table-column prop="admin.name" label="姓名" show-overflow-tooltip></el-table-column>
                <el-table-column prop="admin.mobile" label="手机号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="amount" label="提现金额" show-overflow-tooltip></el-table-column>
                <el-table-column prop="status_name" label="提现状态" show-overflow-tooltip></el-table-column>
                <el-table-column prop="update_time" label="提现时间" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" align="center" fixed="right" width="240">
                    <template slot-scope="scope">
                        <el-button type="success" :disabled="scope.row.status != 0" plain size="mini" @click="Approve(scope.row,10)">通过</el-button>
                        <el-button type="danger" :disabled="scope.row.status != 0" plain size="mini" @click="Approve(scope.row,11)">拒绝</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="paging">
                <el-pagination @current-change="currentChange" @size-change="sizeChange" :current-page="curr" :page-size="row" background layout="total, sizes, prev, pager, next, jumper" :total="count"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            list: [], //充值赠送列表

            curr: 1, //页码
            row: 10, //每页条数
            pages: 1, //总页数
            count: 0, //总条数
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        //提现列表
        getList: function () {
            this.http.post('/admin.adminWithdraw/index', {
                row: this.row,
                curr: this.curr
            }).then(response => {
                this.list = response.list;
                this.curr = response.curr;
                this.pages = response.pages;
                this.count = response.count;
            })
        },
        Approve(row,status){
            this.http.post('/admin.adminWithdraw/status',{
                id:row.id,
                status:status
            }).then(()=>{
                this.$message.success('保存成功！')
                this.getList();
            })
        },
        //翻页
        currentChange: function (curr) {
            this.curr = curr;
            this.getList();
        },
        //改变每页条数
        sizeChange: function (row) {
            this.row = row;
            this.getList();
        },
    },
}
</script>